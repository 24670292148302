import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

function Home() {

    const navigate = useNavigate()

    function TrainerLogin() {
        navigate("/trainer/login")
    }

    return (
        <div className="container mx-auto text-center my-4">
            <h1 >Star Mart Home Page</h1>
            
            <button onClick={TrainerLogin} className="btn btn-dark fs-3 my-4 ">Proceed To Trainer Login</button>
            
        {<Footer />}
        </div>
    )
}

export default Home;