import React, {useState, useEffect} from 'react'
// import { useSelector } from "react-redux"
import BackNavBar from '../../components/BackNavBar';
import SubmittedCarts from '../../components/SubmittedCarts';
const axios = require('axios').default;


function SubmittedCartList () {

    const [students, setStudents] = useState([]);
    const [validatedStudents, setValidatedStudents] = useState([])
    // const trainer = useSelector(state=>state.trainer)
    const [isValidate, setIsValidate] = useState(false)
    var isQualified = true

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/trainer/students/submittedcarts/" + sessionStorage.trainer)
        .then ((response) => {
            setStudents(response.data)
            setIsValidate(false)
        })
        .catch ((error) => {
            console.log(error);
        })
    }, [])

    
    function Refresh(){

        axios.get(process.env.REACT_APP_SERVER_URL + "/trainer/students/submittedcarts/" + sessionStorage.trainer)
        .then ((response) => {
            setStudents(response.data)
            setIsValidate(false)
        })
        .catch ((error) => {
            console.log(error);
        })
    }
    
    function validateScenarioA(){

        const budgetA = 240
        const minRice = 20
        const minBread = 7
        const minDairy = 24
        const minButter = 3
        const minFruit = 40
        const minPaper = 60
        const minEgg = 72
        const minBaking = 3

        setIsValidate(true)
        var rice = 0
        var bread = 0
        var dairy = 0
        var butter = 0
        var fruit = 0
        var paper = 0
        var egg = 0
        var baking = 0
        
        // eslint-disable-next-line
        students.map((student) => {
            if (student.totalAmount > budgetA) {
                isQualified = false

            } else {
                // eslint-disable-next-line
                student.products.map((product) => {

                    //check expiry of product
                    // if (product.expiry) {
                    //     const now = new Date()
                    //     const expiry = new Date(product.expiry)
                    //     if (expiry < now) {
                    //         isQualified = false
                    //     }
                    // }

                    // accumulate product volume for each product
                    if (product.category === "Rice") {
                        rice += (product.volume*product.quantity)  
                    } else if (product.category === "Breads") {
                        bread += (product.volume*product.quantity)   
                    } else if (product.category === "Dairy") {
                        dairy += (product.volume*product.quantity)   
                    }
                    else if (product.category === "Butter") {
                        butter += (product.volume*product.quantity)
                    }

                    else if (product.category === "Fruits") {
                        //Only Peach is added to qualification
                        if (product.name.includes("Peach")) {
                            fruit += (product.volume*product.quantity)
                        }
                    }

                    else if (product.category === "Paper") {
                        paper += (product.volume*product.quantity)   
                    }
                    else if (product.category === "Eggs") {
                        egg += (product.volume*product.quantity)   
                    }

                    else if (product.category === "Baking") {
                        baking += (product.volume*product.quantity)   
                    }  
                })
            }

            //Check if minimum requirement is bought
            if ((rice < minRice) || (bread < minBread) || (dairy < minDairy) || (butter < minButter) || (fruit < minFruit) || (paper < minPaper) || (egg < minEgg) || (baking < minBaking)) {
                isQualified = false
            }

            student.isQualified = isQualified

            // console.log("Student name = " + student.username)
            // console.log("Rice = " + rice)
            // console.log("Bread = " + bread)
            // console.log("Dairy = " + dairy)
            // console.log("Butter = " + butter)
            // console.log(student.username + " is qualified = " + student.isQualified)
 
            rice = 0
            bread = 0
            dairy = 0
            butter = 0
            fruit = 0
            paper = 0
            egg = 0
            baking = 0
            isQualified = true

            
        })

        axios.post(process.env.REACT_APP_SERVER_URL + "/trainer/students/submittedcarts/" + sessionStorage.trainer, students)
        .then((response) => {
            setValidatedStudents(response.data)    
        })
        .catch((error) => {
            console.log(error)
        })
  
    }
 

    function validateScenarioB(){

        const budgetB = 370
        const minRice = 30
        const minBread = 10
        const minDairy = 2
        const minButter = 2
        const minFruit = 80
        const minPaper = 60
        const minEgg = 75
        const minFish = 12
        const minBaking = 4

        setIsValidate(true)
        var rice = 0
        var bread = 0
        var dairy = 0
        var butter = 0
        var fruit = 0
        var paper = 0
        var egg = 0
        var fish = 0
        var baking = 0
        
        // eslint-disable-next-line
        students.map((student) => {
            if (student.totalAmount > budgetB) {
                isQualified = false
                
            } else {
                // eslint-disable-next-line
                student.products.map((product) => {

                    //check expiry of product
                    if (product.expiry) {
                        const now = new Date()
                        const expiry = new Date(product.expiry)
                        if (expiry < now) {
                            isQualified = false
                        }
                    }

                    // accumulate product volume for each product
                    if (product.category === "Rice") {
                        rice += (product.volume*product.quantity)
                    }
                    else if (product.category === "Breads") {
                        bread += (product.volume*product.quantity)
                    }
                    else if (product.category === "Dairy") {
                        dairy += (product.volume*product.quantity) 
                    }
                    else if (product.category === "Butter") {
                        if (product.name.includes("Unsalted")) {
                            butter += (product.volume*product.quantity)
                        }
                    }
                    else if (product.category === "Fruits") {
                        if(product.name.includes("Peach")) {
                        fruit += (product.volume*product.quantity)
                        }
                    }
                    else if (product.category === "Paper") {
                        paper += (product.volume*product.quantity)   
                    }
                    if (product.category === "Eggs") {
                        egg += (product.volume*product.quantity)   
                    }

                    else if (product.category === "Baking") {
                        baking += (product.volume*product.quantity)   
                    }

                    else if (product.category === "Fish") {
                        fish += (product.volume*product.quantity)   
                    }
                })
            }

            //Check if minimum requirement is bought
            if ((rice < minRice) || (bread < minBread) || (dairy < minDairy) || (butter < minButter) || (fruit < minFruit) || (paper < minPaper) || (egg < minEgg) || (fish < minFish) || (baking < minBaking)) {
            isQualified = false
            }

            student.isQualified = isQualified
 
            rice = 0
            bread = 0
            dairy = 0
            butter = 0
            fruit = 0
            paper = 0
            egg = 0
            baking = 0
            isQualified = true
        })

        axios.post(process.env.REACT_APP_SERVER_URL + "/trainer/students/submittedcarts/" + sessionStorage.trainer, students)
        .then((response) => {
            setValidatedStudents(response.data)    
        })
        .catch((error) => {
            console.log(error)
        })
    }
    
return (
    <div className=''>

        <BackNavBar destination="/trainer/" />

        {/* Title */}
        <div className="fs-1 text-center mb-2 mt-3">
            <h1>List of Submitted Carts</h1>
        </div>

        {/* Validate Cart */}
        <div className="hstack my-4">

            {/* Refresh button */}
            <div className="container col-lg-2">
                <button onClick={Refresh} className='container btn btn-primary fs-6 rounded-pill'>Refresh Carts</button>
            </div>

            <div className="container col-lg-2">
                <button onClick={validateScenarioA} className='container btn  btn-primary fs-6 rounded-pill'>Validate Scenario A</button>
            </div>

            <div className="container col-lg-2">
                <button onClick={validateScenarioB} className='btn btn-primary  fs-6 rounded-pill'>Validate Scenario B</button>
            </div>
        </div>

        

    {isValidate ? <SubmittedCarts students={validatedStudents} /> : 
    
    //non-validate carts
    <div className="container">
        <div className="container my-3">
            {/* Students who have not submitted their carts */}
            {students && students.map((student) => (
                (student.totalAmount === 0) && <div className="vstack shadow mx-auto py-0 mb-3" key={student._id} style={{borderRadius: 20}}>
                    <h5 className='ms-4 mt-2'>{student.username}</h5>
                    <h6 className="ms-4">Not Yet Submit</h6>
                    <h6 className="text-center mb-2">Total Amount Spent: $ {(student.totalAmount).toFixed(2)}</h6>
                    </div>
            ))}

        <div className="mx-auto py-4">
        {/* For Students who have submitted their carts */}
            {students && students.map((student) => (
                (student.totalAmount > 0) && <div className="vstack shadow" style={{borderRadius: 20}} key={student._id}>
                    <div className="vstack gap-4 pt-3" >
                        <h4 className="ms-4 mt2">{student.username}</h4>
                        <h6 className="text-center">Total Amount Spent: ${(student.totalAmount).toFixed(2)}</h6>
                        
                        <div className="container mx-auto">
                            <div className="row" >
                                {/* to map products of a single student */}
                                {student.products.map((product) => (
                                    <div className="hstack col-lg-3 col-md-6 col-sm-12 my-2 shadow mx-auto" key={product._id}>

                                        <div className="vstack mt-3 mb-4">
                                            <h5 className="mt-2 my-0 ms-1">{product.name}</h5>
                                            <p className="my-0 ms-1">Price: $ {(product.price).toFixed(2)} {product.quantifier}</p> 
                                            <p className="my-0 ms-1">Quantity: {product.quantity}</p>
                                            {product.expiry &&  <p className="my-0 ms-1">Expiry: {product.expiry}</p>}

                                            <p className="my-0 ms-1 fw-bold">Total: $ {(product.price * product.quantity).toFixed(2)}</p>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>    
                </div>   
            ))}
        </div>

    </div>

    </div>
    }
    {/* end of ternery operator */}
         

    </div>
)
}

export default SubmittedCartList
