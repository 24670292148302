import React from 'react'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const SubmittedCarts = ({students, message}) => {

    return (

        <div className="container">
            <div className="container my-3">
                {/* Students who have not submitted their carts */}
                {students && students.map((student) => (
                    (student.totalAmount === 0) && <div className="vstack shadow mx-auto py-0 mb-3" key={student._id} style={{borderRadius: 20}}>
                        <h5 className='ms-4 mt-2'>{student.username}</h5>
                        <h6 className="ms-4">Not Yet Submit</h6>
                        <h6 className="text-center mb-2">Total Amount Spent: $ {(student.totalAmount).toFixed(2)}</h6>
                    </div>
                ))}

            <div className="mx-auto py-4">
            {/* For Students who have submitted their carts */}
                {students && students.map((student) => (
                    (student.totalAmount > 0) &&
                    <div className="vstack shadow" key={student._id} >
                        <div className="vstack gap-3 py-4">
                            <h4 className="ms-4 mt2" style={{color : student.isQualified ? "green" : "red"}}>{student.username}  {student.isQualified ?  <DoneIcon fontSize="large"/> : <CloseIcon fontSize="large"/>} </h4>
                            
                            <h6 className="text-center">Total Amount Spent: $ {(student.totalAmount).toFixed(2)}</h6>
                        
                            <div className="container mx-auto">
                                <div className="row" >
                                    {/* to map products of a single student */}
                                    {student.products.map((product) => (
                                        <div className="hstack col-lg-3 col-md-6 col-sm-12 my-2 shadow mx-auto" key={product._id}>

                                            <div className="vstack mt-3 mb-4">
                                                <h5 className="mt-2 my-0 ms-1">{product.name}</h5>
                                                <p className="my-0 ms-1">Price: $ {(product.price).toFixed(2)} {product.quantifier}</p>
                                                <p className="my-0 ms-1">Quantity: {product.quantity}</p>

                                                {product.expiry &&  <p className="my-0 ms-1">Expiry: {product.expiry}</p>}

                                                <p className="my-0 ms-1 fw-bold">Total: $ {(product.price * product.quantity).toFixed(2)}</p>

                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                                
                    </div>
                        
                ))}
            </div>

            </div>

        </div>
    )
}

export default SubmittedCarts