import React from 'react'
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackNavBar = ({destination}) => {
  return (

    <nav className="navbar navbar-light bg-light shadow-lg" >
        <Link to={destination}>
            <ArrowBackIcon fontSize="large" className="ms-3 text-dark"/>
        </Link>
    </nav>
   
  )
}

export default BackNavBar