import {Navigate, Outlet} from "react-router-dom";
import React from "react";
// import { AppContext } from "./App";


function ProtectAdminRoute() {
  // const {isAdminAuth} = useContext(AppContext)

  return sessionStorage.adminAuth ? <Outlet/> : <Navigate to="/admin/login" />
}

export default ProtectAdminRoute