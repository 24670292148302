import React, {useState, useEffect} from "react"
import { useSelector } from "react-redux"
import BackNavBar from "../../components/BackNavBar";

const axios = require('axios').default;

function StudentCart() {

    const trainer = useSelector(state=>state.trainer)
    const [student, setStudent] = useState([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/trainer/students/submittedcarts/cart/" + trainer.trainerName + "/" + trainer.studentID)
        .then ((response) => {
            setStudent (response.data[0])       
        })
        .catch ((error) => {
            console.log(error);
        })
    }, [])


    return(

    <div>

        <BackNavBar destination="/trainer/students/submittedcarts" />

        <div className="text-center my-4"><h1>{student.username}'s Cart</h1></div>

        <div className="text-center my-4"><h3>Total Amount Spent: $ {Number(student.totalAmount).toFixed(2)}</h3></div>


        <div className="container">
            <div className="row">

                {student.products && student.products.map((product) => (
                    <div className="hstack col-lg-4 col-md-6" key={product._id}>
                        <div className="vstack rounded-3 container shadow-lg fs-5   mt-4 mb-4">

                            <p className="text-start mb-0">Category: {product.category}</p>
                            <p className="text-start mb-0">Name: {product.name}</p>
                            {product.expiry && <p className="text-start mb-0">Expiry: {product.expiry}</p>}
                            <p className="mb-0">Price: ${(product.price.toFixed(2))}   {product.quantifier} </p>
                            <p className="text-start mb-0">Quantity: {product.quantity}</p>
                            <p className="text-start mb-0">Item total: $ {(product.quantity * product.price).toFixed(2)}</p>
 
                        </div>
                    </div>
                ))}
            </div>
        </div>
</div>
)
}

export default StudentCart
