import React, { useState, useEffect }from "react";
// import { AppContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"
import { addStudent } from "../../redux/studentRedux";
import Footer from "../../components/Footer";

const axios = require('axios').default;

function StudentLogin () {

  const navigate = useNavigate();
  // const {setIsStudentAuth} = useContext(AppContext);
  
  //to put passcode generated from Trainer's passcode generated into student's passcode login
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const passcode = urlParams.get("passcode");

  const[studentName, setStudentName] = useState("")
  const[studentPassword, setStudentPassword] = useState("")
  const[error, setError] = useState("")
  // const student = useSelector((state)=>state.student)
  const dispatch = useDispatch()
  
  useEffect(() => {
    setStudentPassword(passcode)
  }, [])

  function handleSubmit (event) {
  
    event.preventDefault();
    
    let emptyFields = [];
  
      if (!studentName) { emptyFields.push("User Name")}
      if (!studentPassword) { emptyFields.push("User Password")}
      if (emptyFields.length > 0) {setError("Please fill in " + emptyFields)}

      else {
        const student = {studentName, studentPassword}
        
        // dispatch(addStudent(student))
        // console.log(cart);

        axios.post(process.env.REACT_APP_SERVER_URL + "/student/login", student)
        .then((response) => {
          if (response.status === 200) {

            const student = response.data.student
            // console.log(student)
            dispatch(addStudent(student))
            sessionStorage.setItem("studentAuth", true)
            // console.log(sessionStorage.studentAuth)
            // setIsStudentAuth(true)
            setStudentName("");
            setStudentPassword("");
            setError("")
            navigate("/student/marketplace")
          }
        })
        .catch( (error) => {
          if (error) {
            // console.log(error);
            setStudentName("");
            setStudentPassword(student.studentPassword);
            setError(error.response.data.message)
          }
        })
      }
    }

  return (
    <div className="vstack">
    
      <div className="fs-1 text-center my-1"><h1>Star Mart</h1></div>

      <div className="vstack pt-3 pb-2">

        <div className="vstack shadow px-5 mx-auto" style={{width:300, height:350, borderRadius: 20}} >

          {/* Username Div */}
          <div style={{height:20}} className="vstack mt-2">
              <div className="text-start">Username</div> 
              <input className="rounded-pill border-1 ps-2" onChange={(e) => {setStudentName(e.target.value)}} value={studentName} type="text"/>
            </div>

            {/* Password Div */}
            <div className="vstack">
              <div className="text-start">Passcode</div>
              <input className="rounded-pill border-1 ps-2" type="text" value={studentPassword} readOnly />
            </div>

            {/* Login button */}
            <div style={{height:10}} className="vstack">
              <button onClick={handleSubmit} className="btn btn-dark text-decoration-underline border-1 rounded-pill shadow">Login</button>
            </div>

            {/* Error Message */}
            <div style={{height:30}} className="text-danger mx-auto mb-2">
              {error && <p>{error}</p>}
            </div>

        </div>
      </div>

        <div className="mt-2">
          <Footer />
        </div>
    </div>   
  )
}

export default StudentLogin;