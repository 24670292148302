import { createSlice} from "@reduxjs/toolkit"

const trainerSlice = createSlice({
    name: "trainer",
    initialState: {
        trainerName: "",
        trainerPassword: "",
        trainerPasscode: "",
        // studentID: ""
    },

    reducers: {
        addTrainerName: (state, action) => {
            state.trainerName = action.payload
        },

        addTrainerPassword: (state, action) => {
            state.trainerPassword = action.payload
        },

        addTrainerPasscode: (state, action) => {
            state.trainerPasscode = action.payload
        },

        // addStudentID: (state, action) => {
        //     state.studentID = action.payload
        // }

    }
});

export const { addTrainerName, addTrainerPassword, addTrainerPasscode } = trainerSlice.actions
export default trainerSlice.reducer;