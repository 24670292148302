import React from 'react'

function Footer () {

let year = new Date().getFullYear()

  return (
    <div className="container">
      <img src="/images/StarHorizonHR_Logo.jpg" alt="" style={{width:192, height:72}} className="mx-auto d-block"/>
      <p className='text-center'>Copyright © {year}</p>
    </div>
  )
}
export default Footer