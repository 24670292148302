import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch } from "react-redux"
import { addTrainerName } from "../../redux/trainerRedux";
import Footer from "../../components/Footer";

const axios = require('axios').default;

function TrainerLogin () {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");


  function handleSubmit(event) {
    event.preventDefault();

    const form = document.getElementById("trainerForm")
    const trainerFormData = new FormData(form)
      
    axios.post(process.env.REACT_APP_SERVER_URL + "/trainer/login", trainerFormData)
    .then((response) => {      
      if (response.status === 200) {
        sessionStorage.setItem("trainer", response.data)
        sessionStorage.setItem("trainerAuth", true)
        
        setError("");
        form.reset()

        //add trainer name to trainer redux
        dispatch(addTrainerName(response.data.user))
        navigate("/trainer/")
      }
    })
    .catch((error) =>{
      if (error) {
        console.log(error);
        setError(error.response.data)
      }
    });

  }
  return (

    <div className="vstack">
 
      <div className="fs-1 text-center my-1"><h1>Star Mart</h1></div>
      <div className="fs-2 text-center"><h2>Trainer Login Page</h2></div>

      <div className="vstack pt-3 pb-2">
        
        <div className="vstack shadow px-5 mx-auto" style={{width:300, height:320, borderRadius: 20}} >

          <form id="trainerForm" onSubmit={handleSubmit} className="vstack">
            {/* Username Div */}
            <div style={{height:5}} className="vstack">
              <label className="text-start mt-4">Username</label> 
              <input name="trainerName" className="rounded-pill border-1 ps-2" type="text" required />
            </div>

            {/* Password Div */}
            <div style={{height:15}} className="vstack">
              <label className="text-start">Password</label>
              <input type="password" name="trainerPassword" className="rounded-pill border-1 ps-2" required />
            </div>

            {/* Login button */}
            <div style={{height:0}} className="vstack">
              <input type="submit" value="Login" className="btn btn-dark text-decoration-underline border-1 rounded-pill shadow" />
            </div>
          </form>

          {/* Error Message */}
          <div className="text-danger text-center" style={{height:50}} >
            {error && <p className="">{error}</p>}
          </div>

        </div>
      </div>

      <div className="mt-2">
        <Footer />
      </div>
    </div>

  )
}

export default TrainerLogin;
