import React, { useState } from 'react'
import BackNavBar from '../../components/BackNavBar';

const axios = require('axios').default;

function AddProduct () {

    const [filename, setFilename] = useState("")
    const [message, setMessage] = useState("")


    function handleChange(e) {
        const name = e.target.files[0].name.split(".", 1)[0]
        setFilename(name)
    }

    async function confirmProduct(event) {
        event.preventDefault()

        const form = document.getElementById("form")
        const formData = new FormData(form)
        
        try {
            const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/admin/editproducts/addproduct", formData)
            if (response.status === 200) {
                form.reset();
                setFilename("")
                setMessage("Product Added successfully")
            }
        } catch (error) {
            setMessage(error.response.data.message)            
        }

    }

    function ShowHideDiv() {
        var quantifier = document.getElementById("quantifier");
        var quanextend = document.getElementById("quanextend");
        quanextend.style.display = quantifier.value === "for" ? "block" : "none";
    }
    
  return (
    <div>

        <BackNavBar destination="/admin/editproducts" />

        {/* Title */}  
        <div className="fs-2 text-center">
            <h2>Add Product</h2>
        </div>
        
        <div className="container mx-auto">
        
        {/* form data section */}
        <form onSubmit={confirmProduct} id="form">

        <div className="custom-file mb-4 vstack shadow-lg mx-auto" style={{width: 400, borderRadius: 20}}>

                {/* <label className='custom-file-label' htmlFor="customFile" >{filename}</label> */}
                <input onChange={handleChange} className='custom-file-input mx-auto mt-4' name="image" type="file" accept="image/*" required/>
                
                <select required name="category" className='mx-auto my-2 border border-dark border-1 rounded-pill' >
                    <option value=""  >Choose Category</option>
                        {/* For categories in categories */}
                        {/* <option >Category</option> */}
                        <option value="Rice">Rice</option>
                        <option value="Breads">Breads</option>
                        <option value="Eggs">Eggs</option>
                        <option value="Dairy">Dairy</option>
                        <option value="Butter">Butter</option>
                        <option value="Fruits">Fruits</option>
                        <option value="Fish">Fish</option>
                        <option value="Paper">Paper</option>
                        <option value="Baking">Baking</option>
                    </select>

                <input type="text" name="name" value={filename} placeholder="Item Name" className="my-2 mx-auto border border-1 border-dark w-256 h-60 p-10 rounded-pill" required readOnly/>

                <input type="decimal" name="volume" placeholder="Item Volume"  className="my-2 mx-auto border border-dark border-1 w-256 h-60 p-10 rounded-pill" required />

                <input type="decimal" name="price" placeholder="Item Price $"  className="my-2 mx-auto border border-dark border-1 w-256 h-60 p-10 rounded-pill" required />

                <select required id="quantifier" name="itemquantifier" className="mx-auto my-2 border border-dark border-1 rounded-pill" onChange={ShowHideDiv} >
                    <option value="" >Choose Quantifier</option>
                    <option value="">None</option>
                    <option value="per pkt">per pkt</option>
                    <option value="per Kg">per Kg</option>
                    <option value="for">for</option>                      
                </select>
                <div id="quanextend" className="mx-auto my-2" style={{"display": "none"}}>
                    <input name="quanextend" className="border border-dark border-1 mx-auto rounded-pill" type="number" placeholder="Quantity" min="1" />
                </div>

                <div className="hstack mx-auto my-auto"> 
                    <label className='pe-2'>Optional Expiry date: </label>
                    <input type="date" name="expiry"  className="border border-dark border-1 mx-auto my-2 rounded-pill" />
                </div>

                <input type="submit" value="Confirm" className="btn btn-dark mx-auto my-2 text-decoration-underline rounded-pill"/>
            </div>          
        </form>

        
        <div className="text-center" style={{height:50}} >
            {message && <p className="">{message}</p>}
          </div>
         
    </div>

    </div>
  )
}

export default AddProduct