import React from 'react'

function ErrorPage() {
  return (
    <div className="fs-1 text-danger position-absolute top-50 start-50 translate-middle">
      Error! Page not found. <br/>
      Please check URL.
    </div>
  )
}

export default ErrorPage