import {Navigate, Outlet} from "react-router-dom";
import React from "react";
// import { AppContext } from "./App";


function ProtectStudentRoute() {
  // const {isStudentAuth} = useContext(AppContext)
  return sessionStorage.studentAuth ? <Outlet/> : <Navigate to="/student/login" />
}

export default ProtectStudentRoute