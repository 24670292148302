import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { incrementProduct, decrementProduct, removeProduct } from "../../redux/studentRedux";
import {useNavigate} from "react-router-dom"
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BackNavBar from "../../components/BackNavBar";

const axios = require('axios').default;

function Checkout () {

    const student = useSelector((state)=>state.student)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)

    function handleSubmit() {

        setOpen(false)

        //submit cart to backend
        axios.post(process.env.REACT_APP_SERVER_URL + "/student/marketplace/checkout", student)
        .then((response) => {     
        if (response.status === 200) {
            sessionStorage.clear()
            navigate("/student/marketplace/checkout/success")
        }
        })
      .catch((error) =>{
        if (error) {
          console.log(error);
        }
      });
        
    }
    

return (

    <body onLoad="request_promo();" onBeforeUnload="test20();">


    <BackNavBar destination="/student/marketplace" />

    <h1 className="text-center fs-1"> Star Mart Cart </h1>


    {/* Checkout button */}
    <button onClick={()=>{if (student.quantity > 0) setOpen(true) }} className="hstack btn btn-warning col-12 btn btn-light">
        <div className="text-start ps-5 col-6 text-decoration-underline fs-4 text-primary">Checkout</div>
        <div className="text-end pe-5 col-6 fs-4"><b>Total: $ {(student.total).toFixed(2)}</b></div>
    </button>
    

    <div className="container">
        <div className="row">
            {student.products.map((product)=> (
            <div className="pt-4 hstack col-lg-3 col-md-4 col-sm-6">

            {/* Start of products in cart */}
            <div className="hstack mx-auto py-2 border border-1 border-dark shadow " style={{width: 390, borderRadius: 15}}>
        
                <div className="vstack py-0" >
                    <div className="hstack me-2">
                    
                        <div className="vstack mx-1">
                            <p className="my-0">{product.name}</p>
                            {product.expiry && <p className="mb-0">Expiry: {product.expiry}</p>}
                            <p className="">$ {product.price.toFixed(2)} {product.quantifier}</p>
                        </div>

                    </div>
                  
                <div className="hstack">
                    <button onClick={() => {dispatch(decrementProduct(product))}} className="btn btn-light p-0"><RemoveCircleIcon fontSize="large" /></button>
                    
                    <input style={{width: 50}} className="border-0 bg-light text-center" value={product.quantity} />
                    
                    <button onClick={() => {dispatch(incrementProduct(product))}} className="btn btn-light p-0"><AddCircleIcon fontSize="large" /> </button>

                    <div className="hstack mx-auto">
                    <button onClick={() => {dispatch(removeProduct(product))}} className="btn btn-danger text-decoration-underline rounded-pill">Remove</button>
                    </div>
                </div>

                <div className="hstack pt-2 mx-auto ">
                    <b>Product total: $ {(product.quantity * product.price).toFixed(2)}</b>  
                </div>

            </div>
        </div>

    </div>
    ))}
    
    </div>
    {/* End of Cart Items */}


    <>
    <Dialog
        open={open} 
        onClose={()=> setOpen(false)} aria-labelledby='dialog-title' aria-describedby='dialog-description'>
            <DialogTitle id="dialog-title">Submit Cart?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        You will not be able to make changes once you have submitted your cart.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> setOpen(false)}>Cancel</Button>
                    <Button autoFocus onClick={handleSubmit}>Submit</Button>
                </DialogActions>
    </Dialog>
    </>

    </div>

</body>

    )

}
    
export default Checkout
