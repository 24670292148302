import React from "react"

function Success () {

    function Close() {
        window.open("about:blank", "_self");
        window.close();
    }

    return (
        <div className="my-5">

            <div className="my-5" >
                <p className="text-center fs-3">Your cart has been submitted successfully.</p>
                <h1 className="text-center">Thank you for shopping!</h1>

            </div>

            <div className="container text-center">
                <button className="btn btn-dark fs-3 shadow" onClick={Close}>Close</button>
            </div>
            
        </div>
    )
}

export default Success;