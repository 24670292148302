import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import BackNavBar from '../../components/BackNavBar';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

const axios = require('axios').default;


function AdminStudentList() {
    
    const [students, setStudents] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/admin/students")
        .then ((response) => {
            setStudents(response.data);
        })
        .catch ((error) => {
            console.log(error);
        })
    }, [])

    // function handleView (studentID) {
    //     console.log("View StudentID: " + studentID + " clicked" )
        
    // }

    function handleDelete(studentID) {

        axios.delete(process.env.REACT_APP_SERVER_URL + "/admin/students/" + studentID)
        .then (() => {
            setStudents(students.filter( (student) => {
                return student._id !== studentID}));
        })
        .catch ((error) => {
            console.log(error);
        })
    }


    function deleteAllStudents() {
        axios.delete(process.env.REACT_APP_SERVER_URL + "/admin/students/")
        .then ((response) => {
            setStudents(response.data)
            return (students)
        })
        .catch ((error) => {
            console.log(error);
        })
    }


return (

    <div>

        <BackNavBar destination="/admin" />

        {/* Title */}
        <div className="fs-1 text-center mb-3"><h1>Table of Teams</h1></div>

        <div className='hstack'>

            {/* Add Team Link */}
            <div className="hstack mx-auto mb-4" style={{width: 200}}>
                <Link to="" className="mx-auto text-dark fs-3">Add Team</Link>
            </div>

            {/* Delete All Teams Button*/}
            <div className="hstack  mb-4" style={{width: 200}}>
                <button onClick={deleteAllStudents}  className="mx-auto rounded-pill text-danger fs-4">Delete all</button>
            </div>
        </div>

    {/* List of Teams */}
    <div className="container">
        <div className="row" >
            {students && students.map((student) => (
                <div className="hstack col-lg-3 col-md-6 col-sm-12 my-2" key={student._id}>
                    <div style = {{width: 250, height: 180, borderRadius: 15}} className="card hstack shadow border border-dark border-1">
                        <div key="" className="">
                            <p className="ms-3 p-0 text-primary">Username: <span className="text-dark">{student.username}</span> </p>
                            <p className="ms-3 p-0 text-primary">Passcode: <span className="text-dark">{student.password}</span> </p>
                            <p className="ms-3 p-0 text-primary">Trainer: <span className="text-dark">{student.assignedTrainer}</span> </p>
                                    
                            <div className="container hstack">
                                {/* <div className="hstack pe-5">
                                    <button onClick={() => handleView(student._id)} className="btn btn-light text-dark"><VisibilityIcon /></button>
                                </div> */}

                                <div className="hstack ps-4">
                                    <button onClick={() => handleDelete(student._id)} className="btn btn-light bg-transparent text-danger"><DeleteIcon/></button>
                                </div>
                            </div>
                        </div>
                    </div>                        
                </div>
            ))}

        </div>
    </div>

    </div>

  )
}

export default AdminStudentList