import React, {useState} from 'react'
import {Link} from "react-router-dom";
// import { FaArrowLeft, FaTrash, FaPen } from 'react-icons/fa'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//Bootstrap hamburger menu
import {Nav, Navbar, Modal, Form, Button} from 'react-bootstrap';
// import Navbar from 'react-bootstrap/Navbar';


const axios = require('axios').default

function EditProducts () {

    const [products, setProducts] = useState([])
    const [updatedProduct, setUpdatedProduct] = useState({})
    const [show, setShow] = useState(false)

    function handleSubmit(event) {
        event.preventDefault();
        
        axios.get(process.env.REACT_APP_SERVER_URL + "/admin/editproducts/" +  event.target.value)
        .then((response) => {
            setProducts(response.data)
        })
        .catch((error) => {
            if (error) {
                console.log(error);
            }
        })
    }

    async function handleEdit (product) {

        setUpdatedProduct(product)
        handleShow()
    }

    function handleChange(event) {
        const {name, value} = event.target
        setUpdatedProduct((prevValue) => {
            return {
                ...prevValue,
                [name]: value 
            }
        })
    }

    function handleClose() {setShow(false)}
    function handleShow() {setShow(true)}

    async function submitProduct (event) {
        event.preventDefault()

        const form = document.getElementById("editProductForm")
        const editProductFormData = new FormData(form) 

        axios.patch(process.env.REACT_APP_SERVER_URL + `/admin/editproducts/${updatedProduct._id}`, editProductFormData)
        .then((response) => {
            if (response.status === 200) {
                handleClose()

                axios.get(process.env.REACT_APP_SERVER_URL + `/admin/editproducts/${updatedProduct.category}`)
                .then((response) => {
                    setProducts(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
            }
        })
        .catch((error) => {
            console.log(error)
        });

    }

    // function showHideDiv() {
    //     var quantifier = document.getElementById("quantifier");
    //     var quanextend = document.getElementById("quanextend");
    //     quanextend.style.display = quantifier.value === "for" ? "block" : "none";
    // }


    const handleDelete = async (id) => {
        axios.delete(process.env.REACT_APP_SERVER_URL + `/admin/editproducts/${id}`)
        .then(() => {
            setProducts(products.filter( (product) => {
                return product._id !== id;
            }))
        })
}

  return (
    <div>

    <Navbar className="bg-light shadow "  expand="md">
        <div className="container ms-4">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">

            <Link to="/admin" className='me-2 my-auto'>
                <ArrowBackIcon fontSize="large" className="ms-3 mt-3 text-dark"/>
            </Link>

            <Nav.Item>
                <li className="nav-item" >
                    <button onClick={handleSubmit} value="Rice" className="btn nav-link text-dark text-decoration-underline fs-5">Rice</button>
                </li>
            </Nav.Item>
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Breads" className="btn nav-link text-dark text-decoration-underline fs-5">Breads</button>
                </li>
            </Nav.Item>
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Eggs" className="btn nav-link text-dark text-decoration-underline fs-5">Eggs</button>
                </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Dairy" className="btn nav-link text-dark text-decoration-underline fs-5">Dairy</button>
                </li>
            </Nav.Item>

            
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Butter" className="btn nav-link text-dark text-decoration-underline fs-5">Butter</button>
                </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item pe-2">
                    <button onClick={handleSubmit} value="Fruits" className="btn        nav-link text-dark text-decoration-underline fs-5">Fruits</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Fish" className="btn      nav-link text-dark text-decoration-underline fs-5">Fish</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Paper" className="btn         nav-link text-dark text-decoration-underline fs-5">Paper</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Baking" className="btn nav-link text-dark text-decoration-underline fs-5">Baking</button>
                </li>
            </Nav.Item>
            
            </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>

        <div className="hstack mx-auto mb-4" style={{width: 200}}>    
            <Link to="/admin/editproducts/addproduct" className='pt-3 mx-auto text-dark fs-3'>Add Product</Link>
        </div>


        <div className="container">
            <div className="row">
                {products && products.map((product) => (
                    <div className="hstack col-lg-3 col-md-4" key={product._id}>
                        <div className="vstack container shadow-lg fs-5 mt-4 mb-4" style={{borderRadius:20}} >

                            <img src={__dirname + "images/"+product.image} style={{width:150, height:150}} className="mx-auto rounded-3 mt-4" alt=""/>
                            <p className="text-start mb-0">{product.name}</p>

                            {product.expiry && <p className="text-start mb-0">Expiry: {product.expiry}</p>}
                            
                            <p className="mb-0">$ {(Number(product.price)).toFixed(2)} {product.quantifier} </p>

                            <div className="hstack mx-auto gap-5 pb-2">

                                <button onClick={() => {handleEdit(product)}} className="btn btn-light bg-transparent text-warning"><EditIcon /></button>

                                <button onClick={() => handleDelete(product._id)} className="btn btn-light bg-transparent text-danger"><DeleteIcon /></button>

                            </div>

                        </div>
                    </div>
                )) }
            </div>
        </div>
  

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                Edit Product
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form id="editProductForm" className="container" onSubmit={submitProduct} >
                
                <Form.Label>New Category</Form.Label>
                    <Form.Select className="mb-3" name="category" value={updatedProduct.category} onChange={handleChange}>

                        <option>Rice</option>
                        <option>Breads</option>
                        <option>Eggs</option>
                        <option>Dairy</option>
                        <option>Butter</option>
                        <option>Fruits</option>
                        <option>Fish</option>
                        <option>Paper</option>
                        <option>Baking</option>
                    </Form.Select>



                    <Form.Group className="mb-3">
                        <Form.Label >New Name</Form.Label>
                        <Form.Control type="text" name="name"
                        value={updatedProduct.name} 
                        onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label >New Volume</Form.Label>
                        <Form.Control type="decimal" name="volume"
                        value={updatedProduct.volume} 
                        onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label >New Price $</Form.Label>
                        <Form.Control type="decimal" name="price"
                        value={updatedProduct.price} 
                        onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Label>New Quantifier</Form.Label>
                    <Form.Control type="String" id="quantifier" name="quantifier" className="mb-3" value={updatedProduct.quantifier} onChange={handleChange}>
                        {/* <option>None</option>
                        <option>per pkt</option>
                        <option>per Kg</option>
                        <option>for</option> */}
                        {/* <Form.Control type="text" name="username" 
                        value={updatedTrainer.username} 
                        onChange={handleChange}
                        /> */}
                    </Form.Control>

                    {/* <Form.Group className="mb-3">
                        <Form.Label >New Quantity</Form.Label>
                        <Form.Control type="number" name="quanextend"
                        value={updatedProduct.quantifier} 
                        onChange={handleChange}
                        />
                    </Form.Group> */}

                    <Form.Group className="mb-3">
                        <Form.Label >New Expiry (optional)</Form.Label>
                        <Form.Control type="date" name="expiry"
                        value={updatedProduct.expiry} 
                        onChange={handleChange}
                        />
                    </Form.Group>

                    <Button variant="success" type="submit">Submit</Button>
        
                </Form>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>Close</Button>
        </Modal.Footer>
    </Modal>
    

</div>


  )
}

export default EditProducts
