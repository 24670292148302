import React from "react";
import { Link, useNavigate } from "react-router-dom";


const Admin = () => {

    const navigate = useNavigate();

    function logout () {
        sessionStorage.clear()
        navigate("/admin/login")
    }

    return (
        
        <div className="">

            <div className="text-center mb-4"><h1>Admin Page</h1></div>

            <div className="container">
                <div className="row mx-auto">

                    <div className="hstack col-lg-4 col-md-6 col-sm-12">
                        <Link to="/admin/students" className="text-dark">
                            <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Table of Teams</button>
                        </Link>
                    </div>


                    <div className="hstack col-lg-4 col-md-6 col-sm-12"> 
                        <Link to="/admin/trainers" className="text-dark">
                            <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Table of Trainers</button>
                        </Link>
                    </div>

                    <div className="hstack col-lg-4 col-md-6 col-sm-12">
                        <Link to="/admin/editproducts" className="text-dark">
                            <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>
                            Edit Shopping Items</button>
                        </Link>
                    </div>
                    
                    <div className="hstack col-lg-4 col-md-6 col-sm-12">
                        <Link to="" className="text-dark">
                            <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>View Promotion</button>
                        </Link>
                    </div>

                    <div className="hstack col-lg-4 col-md-6 col-sm-12">
                        <Link to="" className="text-dark">
                            <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Reset Options</button>
                        </Link>
                    </div>

                    <div className="hstack col-lg-4 col-md-6 col-sm-12">
                        {/* <Link to="/admin/logout"> */}
                            <button onClick={logout} className="btn btn-light text-decoration-underline text-danger shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Logout</button>
                        {/* </Link> */}
                    </div>

                </div>

            </div>

        </div>
    )
}
 
export default Admin;
