import React, { useState } from "react";
import BackNavBar from "../../components/BackNavBar";
// import {Nav, Navbar, Modal, Form, Button} from 'react-bootstrap'

const axios = require('axios').default;

function AddTrainer ()  {

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("")

    async function addTrainer(event) {
        event.preventDefault();

        const form = document.getElementById("addTrainerForm")
        const addTrainerFormData = new FormData(form)

        axios.post(process.env.REACT_APP_SERVER_URL + "/admin/addtrainer", addTrainerFormData)
        .then((response) => {
            if (response.status === 201) {
                setSuccess(response.data.username + " created")
                form.reset()   
            }
        })
        .catch((error) =>{
            if (error) {
                setError(error.response.data.message)
            }
        });    
    
    }

    return (
        <div>
            {/* Nav Bar */}
            <BackNavBar destination="/admin/trainers" />  

            {/* Title */}
            <div className="fs-2 text-center"><h2>Add Trainer</h2></div>

            <div className="vstack py-2">
                
                <form onSubmit={addTrainer} id="addTrainerForm" className="vstack shadow px-5 mx-auto" style={{width:300, height:300, borderRadius: 20}}>
                                
                        <input name="username" type="text" style={{height:30}} placeholder="Trainer's UserName" className="rounded-pill border-1 ps-3 my-4" required/>

                        <input name="password" type="text" style={{height:30}} placeholder="Password" className="rounded-pill border-1 ps-3 my-4" required />
                                
                    {/* Button */}
                        <input type="submit" value="Add" style={{height:30}} className="btn btn-dark text-decoration-underline border-1 rounded-pill shadow my-1" />
                </form>

                {/* Error Message Div */}
                <div className="container border-black py-2" style={{height:100}}>
                    {!error && <div className="noError text-center">{success}</div>}
                    {error && <div className="error text-danger text-center">{error}</div>}
                </div>               
            </div>
        </div> 
    );
}

export default AddTrainer;

