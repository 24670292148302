import {Navigate, Outlet} from "react-router-dom";
import React from "react";
// import { AppContext } from "./App";


function ProtectTrainerRoute() {
  // const {isTrainerAuth} = useContext(AppContext)
  return sessionStorage.trainerAuth ? <Outlet/> : <Navigate to="/trainer/login" />
}

export default ProtectTrainerRoute