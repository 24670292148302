import React from "react";
import { Link, useNavigate  } from "react-router-dom";


const TrainerPage = () => {

    const navigate = useNavigate();

    function logout () {
        sessionStorage.clear()
        navigate("/trainer/login")
    }

    return (

        <div className="container">
            <div className="vstack">
                
                <div className="text-center my-4"><h1>Trainer's Page</h1></div>
                
                <div className="container">
                    <div className="row mx-auto">

                        <div className="hstack col-lg-4 col-md-6 col-sm-12">
                            <Link to="/trainer/students" className="text-dark">
                                <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Table of Teams</button>
                            </Link>
                        </div>

                        <div className="hstack col-lg-4 col-md-6 col-sm-12">
                            <Link to="/trainer/students/submittedcarts" className="text-dark">
                                <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>List of Submitted Carts</button>
                            </Link>
                        </div>

                        <div className="hstack col-lg-4 col-md-6 col-sm-12">
                            <Link to="/trainer/products" className="text-dark">
                                <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>List of Shopping Items</button>
                            </Link>
                        </div>

                        <div className="hstack col-lg-4 col-md-6 col-sm-12">
                            <Link to="/trainer/passcode" className="text-dark">
                                <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Passcode</button>
                            </Link>
                        </div>

                        <div className="hstack col-lg-4 col-md-6 col-sm-12">
                            <Link to="" className="text-dark">
                                <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Launch Promotion</button>
                            </Link>
                        </div>

                        <div className="hstack col-lg-4 col-md-6 col-sm-12">
                            <Link to="" className="text-dark">
                                <button className="btn btn-light text-decoration-underline shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Reset Launched Promotion</button>
                            </Link>
                        </div>

                        <div className="hstack col-lg-4 col-md-6 col-sm-12">
                            {/* <Link to="/trainer/logout"> */}
                                <button onClick={logout} className="btn btn-light text-decoration-underline text-danger shadow-lg mb-5 " style={{height:100, width: 300, borderRadius: 15}}>Log out</button>
                            {/* </Link> */}
                        </div>
                        
                    </div>
                </div>

                
            </div>
        </div>
   
    )
}

export default TrainerPage;
