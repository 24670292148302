
import React, { useEffect, useState } from "react";
// import { FaTrash, FaPen, FaArrowLeft } from 'react-icons/fa'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from "react-router-dom"
import BackNavBar from "../../components/BackNavBar";
// import EditTrainer from "../../components/EditTrainer";
import { Modal, Form, Button} from "react-bootstrap"
const axios = require('axios').default;

function TrainerList() {

    const [trainers, setTrainers] = useState([]);
    const [show, setShow] = useState(false)
    const [updatedTrainer, setUpdatedTrainer] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/admin/trainers")
        .then ((response) => {
            setTrainers(response.data); 
        })
        .catch ((error) => {
            console.log(error);
        })
    }, [])

    
    const handleDelete = async (id) => {
        
        axios.delete(process.env.REACT_APP_SERVER_URL + `/admin/trainers/${id}`)
        .then(() => {
            setTrainers(trainers.filter( (trainer) => {
                return trainer._id !== id;
            }))
        })
}

    async function handleEdit(trainer) {
        setUpdatedTrainer(trainer)
        handleShow()
    }

    function handleClose () {
        setShow(false) 
        setErrorMessage("")
    }
    
    function handleShow() {setShow(true)}

    function handleChange(event) {
        const {name, value} = event.target
    
        setUpdatedTrainer((prevValue) => {
            return {
                ...prevValue,
                [name]: value 
            }
        })
    }

    async function submitTrainer (event) {
        event.preventDefault()
        const form = document.getElementById("editTrainerForm")
        const editTrainerFormData = new FormData(form) 

        axios.put(process.env.REACT_APP_SERVER_URL + `/admin/trainers/${updatedTrainer._id}`, editTrainerFormData)
        .then((response) => {
            if (response.status === 200) {
                handleClose()
                axios.get(process.env.REACT_APP_SERVER_URL + "/admin/trainers")
                .then ((response) => {
                setTrainers(response.data); 
                })
                .catch ((error) => {
                console.log(error);
                })
            }    
        })
        .catch((error) => {
            console.log(error)
            if (error.response.data.codeName === "DuplicateKey")
            setErrorMessage("Duplicate Trainer")
        });   
    }


    return(
        <div className="">

            <BackNavBar destination="/admin" />

            {/* Title */}
            <div className="fs-1 text-center mb-3"><h1>Table of Trainers</h1></div>

            {/* Add Trainer Link */}
            <div className="hstack mx-auto mb-4" style={{width: 200}}>
                <Link to="/admin/trainers/addTrainer" className="mx-auto text-dark fs-3">Add Trainer</Link>
            </div>
 
            {/* List of Trainers */}
            <div className="container">
                <div className="row">
                    {trainers && trainers.map((trainer) => (
                    <div className="hstack col-lg-3 col-md-6 col-sm-12 my-2" key={trainer._id}>
                        <div style = {{width: 250, height: 180, borderRadius: 15}} className="card hstack shadow border border-dark border-1">
                            <div key="" className="">
                                <p className="ms-3 p-0 text-primary">Username: <span className="text-dark">{trainer.username}</span></p>
                                {/* <p className="ms-3 p-0 text-primary">Password: <span className="text-dark">{trainer.password}</span></p> */}
                                <p className="ms-3 p-0 text-primary">Passcode: <span className="text-dark">{trainer.passcode}</span></p>
                                    
                                <div className="container hstack">
                                    <div className="hstack pe-5">
                                        <button onClick={() => {handleEdit(trainer)}} className="btn btn-light text-warning bg-transparent"><EditIcon /> </button>
                                    </div>

                                    <div className="hstack ps-4">
                                        <button onClick={() => handleDelete(trainer._id)} className="btn btn-light text-danger bg-transparent"><DeleteIcon/></button>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    ))}

                </div>
            </div>
        

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                Edit Trainer
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form id="editTrainerForm" className="container" onSubmit={submitTrainer}>
                <Form.Group className="mb-3">
                    <Form.Label>New Username</Form.Label>
                    <Form.Control type="text" name="username" value={updatedTrainer.username} onChange={handleChange}/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label >New Password</Form.Label>
                    <Form.Control type="text" name="password" placeholder="New Password" value={updatedTrainer.password} onChange={handleChange}/>
                </Form.Group>

                <Button type="submit" variant="success">Submit</Button>
    
            </Form>
        </Modal.Body>

        <Modal.Footer>

            {errorMessage && <p className="text-danger text-center shadow-lg">{errorMessage}</p>}
            <Button variant="dark" onClick={handleClose}>Close</Button>
        </Modal.Footer>

        
    </Modal>

        </div>
    )
}

export default TrainerList;