import React, {useState} from "react";
import QRCode from "qrcode";
import { useDispatch } from "react-redux"
import { addTrainerPasscode } from "../../redux/trainerRedux";
import BackNavBar from "../../components/BackNavBar";

const passwordGenerator = require ("generate-password")
const axios = require('axios').default;

function PasscodePage() {

  const [qrcode, setQrcode] = useState("")
  const [passcode, setPasscode] = useState ("")
  const dispatch = useDispatch()

  
  function generatePasscode(){
    return passwordGenerator.generate({
      length: 10,
      numbers: true
    })
  }

  function generateQRCode() {
    
    let word = generatePasscode();
    dispatch(addTrainerPasscode(word))
    setPasscode(word);
    
    const name = sessionStorage.trainer

    axios.put(process.env.REACT_APP_SERVER_URL + "/trainer/passcode", {name, word})
    .then((response) => {
      console.log(response)
    })
    .catch((error) => {
      console.log(error)
    })
  
    QRCode.toDataURL(process.env.REACT_APP_CLIENT_URL + "/student/login?passcode=" + word, { version: 8 }, (err, url) => {
      if (err) {
        console.log (err)
      }
      setQrcode(url)
    })

  }

  return (

    <div> 
      <BackNavBar destination="/trainer" />


      <div className="container">
        <div className="vstack">

          <div className="mx-auto"><h1>Passcode</h1></div>

          <div className="mx-auto" style={{padding: 20, marginTop: 20,  borderRadius: 20}}>
            <img src={qrcode} id="qrcode" style={{maxWidth: 900}} alt="" />
          </div>

          <div className="container">
            <div className="vstack">

              <div className="bg-light fs-5 border border-2 border-light mb-2   "><p className="text-center my-1 ">{passcode}</p></div>

              <div className="hstack gap-3">

                <button onClick={() => {setQrcode(""); setPasscode("")}} className  ="btn btn-danger col-6 text-decoration-underline rounded-3 shadow  text-white fs-5">Clear</button>

                <button onClick={generateQRCode} className="btn btn-dark col-6  text-decoration-underline rounded-3 shadow text-white  fs-5">Generate</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default PasscodePage;