import { configureStore } from "@reduxjs/toolkit";
import studentReducer from "./studentRedux"
import trainerReducer from "./trainerRedux"

const store = configureStore ({
    reducer: {
        student: studentReducer,
        trainer: trainerReducer
    }
})

export default store

