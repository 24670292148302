import { createSlice} from "@reduxjs/toolkit"

const studentSlice = createSlice({
    name: "student",
    initialState: {
        studentID: "",
        studentName: "",
        studentPasscode: "",
        assignedTrainer: "",
        products: [],
        quantity: 0,
        total: 0
    },

    reducers: {
        addProduct: (state, action) => {
            state.quantity += 1
            state.products.push(action.payload)
            state.total += action.payload.price * action.payload.quantity;
        },

        removeProduct: (state, action) => {
            state.quantity -= 1
            state.products = state.products.filter((product) => product._id !== action.payload._id)
            state.total -= action.payload.price * action.payload.quantity
        },

        incrementProduct: (state, action) => {
            // eslint-disable-next-line
            state.products.map((product) => {
                if (product._id === action.payload._id) {
                    product.quantity += 1
                    state.total += action.payload.price
                }
             })
        },

        decrementProduct: (state, action) => {
            // eslint-disable-next-line
            state.products.map((product) => {
                if (product._id === action.payload._id) {
                    if (product.quantity > 1) {
                        product.quantity -= 1
                        state.total -= action.payload.price
                    }
                }
            })
        },

        addStudent: (state, action) => {
            state.studentName = action.payload.username;
            state.studentPasscode = action.payload.password;
            state.assignedTrainer = action.payload.assignedTrainer
            state.studentID = action.payload._id
        }
    }
});

export const { addProduct, incrementProduct, decrementProduct, removeProduct, addStudent } = studentSlice.actions
export default studentSlice.reducer;