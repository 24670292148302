import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import React, {useState, useEffect, createContext} from "react";
import ProtectAdminRoute from "./ProtectAdminRoute";
import ProtectTrainerRoute from "./ProtectTrainerRoute";
import ProtectStudentRoute from "./ProtectStudentRoute";

//pages and components
//Admin Pages
import AdminLogin from "./pages/Admin/AdminLogin";
import Admin from "./pages/Admin/Admin";
import AddTrainer from "./pages/Admin/AddTrainer";
import TrainerList from "./pages/Admin/TrainerList";
import AdminStudentList from "./pages/Admin/AdminStudentList"
import EditProducts from "./pages/Admin/EditProducts";
import AddProduct from "./pages/Admin/AddProduct";


//Trainer Pages:
import TrainerLogin from "./pages/Trainer/TrainerLogin";
import TrainerPage from "./pages/Trainer/TrainerPage";
import PasscodePage from "./pages/Trainer/PasscodePage"
import ProductsPage from "./pages/Trainer/ProductsPage";
import StudentList from "./pages/Trainer/StudentList";
import SubmittedCartList from "./pages/Trainer/SubmittedCartList";
import StudentCart from "./pages/Trainer/StudentCart";

//Student Pages:
import StudentLogin from "./pages/Student/StudentLogin"
import Marketplace from "./pages/Student/Marketplace"
import Checkout from "./pages/Student/Checkout"
import Success from "./pages/Student/Success"
import Home from "./pages/Home"
import ErrorPage from "./pages/ErrorPage";

export const AppContext = createContext(null);

export const URL = process.env.REACT_APP_SERVER_URL

const axios = require('axios').default;

function App() {

  const [isTrainerAuth, setIsTrainerAuth] = useState(true);
  const [isAdminAuth, setIsAdminAuth] = useState(true);
  const [isStudentAuth, setIsStudentAuth] = useState(false);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL + "/admin/login")
    .then((response) => {
      setIsAdminAuth(response.data.isAdminAuth)
      // console.log("Use Effect in React App.js isAdminAuth: " + isAdminAuth)
    })

    axios.get(process.env.REACT_APP_SERVER_URL + "/trainer/login")
    .then((response) => {
      setIsTrainerAuth(response.data.isTrainerAuth)

    })

  }, [])


  return (

    <AppContext.Provider value={{isTrainerAuth, setIsTrainerAuth, isAdminAuth, setIsAdminAuth, isStudentAuth, setIsStudentAuth}}>
      <div className="App">

        <Router>
        
          <div className="pages">
          <Routes>

            {/* Home Page */}
            <Route path="/" element={<Home />} />

            {/* Admin Public Routes */}
            <Route path="/admin/login" element={<AdminLogin />} />

            {/* Admin Protected Routes */}
            <Route element={<ProtectAdminRoute />}>
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/trainers" element={<TrainerList />} />
              <Route path="/admin/trainers/addtrainer" element={<AddTrainer />} />
              <Route path="/admin/students" element={<AdminStudentList />} />
              <Route path="/admin/editproducts" element={<EditProducts />} />
              <Route path="/admin/editproducts/addproduct" element={<AddProduct />} />
            </Route>

            {/* Trainer Public Routes */}
            <Route path="/trainer/login" element={<TrainerLogin />} />
            
            {/* Trainer Protected Routes */}
            <Route element={<ProtectTrainerRoute />}>
              <Route path="/trainer" element={<TrainerPage />} />
              <Route path="/trainer/products" element={<ProductsPage />} />
              <Route path="/trainer/passcode" element={<PasscodePage />} />
              <Route path="/trainer/students" element={<StudentList />} />
              <Route path="/trainer/students/submittedcarts" element={<SubmittedCartList />} />
              <Route path="/trainer/students/submittedcarts/cart" element={<StudentCart />} />
            </Route>

            {/* Student Public Routes */}
            <Route path="/student/login" element={<StudentLogin />} />

            {/* Student Protected Routes */}
            <Route element={<ProtectStudentRoute />}>
              <Route path="/student/marketplace" element={<Marketplace />} />
              <Route path="/student/marketplace/checkout" element={<Checkout />} />
              <Route path="/student/marketplace/checkout/success" element={<Success />} />
            </Route>

            <Route path="*" element={<ErrorPage />} />
            
          </Routes>

        </div>

      </Router>
      
    </div>
    </AppContext.Provider>
  );
}

export default App;
