import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Footer from "../../components/Footer";

const axios = require('axios').default;

function AdminLogin() {
  
  const navigate = useNavigate();

  const [error, setError] = useState("");

    function handleSubmit(event){
      event.preventDefault(); 

        const form = document.getElementById("adminForm")
        const adminFormData = new FormData(form)
        
        axios.post(process.env.REACT_APP_SERVER_URL + "/admin/login", adminFormData)
        .then((response) => {
          setError("");
          if (response.status === 200)
          sessionStorage.setItem("user", response.data)
          sessionStorage.setItem("adminAuth", true)
          navigate("/admin") 
        })
        .catch((error) =>{
          if (error) {
            setError(error.response.data.message);
          }
        })
      
    }

  return (

    <div className="vstack">

      <div className="fs-1 text-center my-1"><h1>Star Mart</h1></div>
      <div className="fs-2 text-center"><h2>Admin Login Page</h2></div>

      <div className="vstack pt-3 pb-2">
        
        <div className="vstack shadow px-5 mx-auto" style={{width:300, height:320, borderRadius: 20}} >

          <form id="adminForm" onSubmit={handleSubmit} className="vstack">
            {/* Username Div */}
            <div style={{height:5}} className="vstack">
              <label className="text-start mt-4">Username</label> 
              <input name="adminName" className="rounded-pill border-1 ps-2" type="text" required />
            </div>

            {/* Password Div */}
            <div style={{height:15}} className="vstack">
              <label className="text-start">Password</label>
              <input type="password" name="adminPassword" className="rounded-pill border-1 ps-2" required />
            </div>

            {/* Login button */}
            <div style={{height:0}} className="vstack">
              <input type="submit" value="Login" className="btn btn-dark text-decoration-underline border-1 rounded-pill shadow" />
            </div>
          </form>

          {/* Error Message */}
          <div className="text-danger text-center" style={{height:50}} >
            {error && <p className="">{error}</p>}
          </div>

        </div>
      </div>

      <div className="mt-2">
        <Footer />
      </div>

    </div>
  )

}

export default AdminLogin;